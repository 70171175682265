<template>
  <div>
    <header-slot>
      <template #actions>
        <div>
          <b-button
            variant="success"
            v-if="module === 16"
            @click="openCreateChargeBackModal"
          >
            <feather-icon
              icon="PlusIcon"
              size="15"
              class="mr-50 text-white"
            />Create
          </b-button>
        </div>
      </template>
    </header-slot>
    <b-nav pills class="m-0">
      <b-nav-item
        :to="{ name: this.$route.meta.routeInProcess }"
        exact
        exact-active-class="active"
        :link-classes="['px-3', bgTabsNavs]"
        >In process</b-nav-item
      >
      <b-nav-item
        :to="{ name: this.$route.meta.routeApproved }"
        exact
        exact-active-class="active"
        :link-classes="['px-3', bgTabsNavs]"
        >Approved</b-nav-item
      >
    </b-nav>
    <b-card
      no-body
      class="border-top-primary border-3 border-table-radius px-0"
    >
      <router-view :key="key" />
    </b-card>
    <create-charge-back-modal
      v-if="openCreateChargeBackModalController"
      @reload="key++"
      @close="closeCreateChargeBackModal"
    />
  </div>
</template>

<script>
import CreateChargeBackModal from "@/views/commons/components/charge-back/charge-back-module/modals/CreateChargeBackModal.vue";
export default {
  components: {
    CreateChargeBackModal,
  },
  data() {
    return {
      openCreateChargeBackModalController: false,
      module: "",
      key: 0,
    };
  },
  computed: {
    moduleId() {
      return this.$route.matched[0].meta.module;
    },
  },
  async created() {
    this.module = this.moduleId;
  },
  methods: {
    openCreateChargeBackModal() {
      this.openCreateChargeBackModalController = true;
    },
    closeCreateChargeBackModal() {
      this.openCreateChargeBackModalController = false;
    },
  },
  watch: {
    "$route.name"(newVal) {
      this.key = newVal;
    },
  },
};
</script>

<style scoped></style>
