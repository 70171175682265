<template>
  <b-modal
    v-model="onControl"
    title="Create Charge Back"
    title-tag="h3"
    modal-class="modal-primary"
    size="xmd"
    @hidden="close"
  >
    <b-container>
      <validation-observer ref="form">
        <validation-provider v-slot="{ errors }" name="card" rules="required">
          <b-form-group id="input-group-1" label="Card:" label-for="input-1">
            <div style="display: grid; grid-template-columns: 10fr 2fr">
              <b-form-input
                id="input-1"
                class="form-control"
                maxlength="4"
                v-mask="'####'"
                v-model="cardNumbers"
                placeholder="Enter the last 4 digits of the card"
                @keyup.enter="getCardsList"
              />
              <b-button class="ml-1" variant="primary" @click="getCardsList">
                <feather-icon icon="SearchIcon" /> Search
              </b-button>
            </div>
            <span v-if="errors[0]" class="text-danger">
              Please fill this field</span
            >
          </b-form-group>
        </validation-provider>
      </validation-observer>
      <b-table
        id="cards-list"
        ref="cardsList"
        no-border-collapse
        class="position-relative"
        :fields="fields"
        show-empty
        no-provider-filtering
        sticky-header="50vh"
        primary-key="id"
        table-class="text-nowrap"
        responsive="sm"
        :items="items"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :busy="isBusy"
      >
        <template #table-busy>
          <div class="text-center text-primary my-2">
            <b-spinner class="align-middle mr-1" />
            <strong>Loading ...</strong>
          </div>
        </template>
        <template #cell(created_at)="data">
          {{ data.item.created_at | myGlobalWithHour }}
        </template>
        <template #cell(actions)="data">
          <div class="d-flex justify-content-center">
            <feather-icon
              class="mr-1 pointer text-primary"
              icon="EyeIcon"
              size="18"
            />
          </div>
        </template>
        <template #cell(amount)="data">
          $ {{data.value|currency  }}
        </template>
        <template #cell(radio)="data">
          <b-form-radio
            v-model="selectedCard"
            unchecked-value="not_accepted"
            name="checkbox-2"
            :value="data.item.transaction_id"
          ></b-form-radio>
        </template>
      </b-table>
      <b-form-group id="input-group-2" label="Description:" label-for="input-2">
        <b-form-textarea
          v-model="description"
          id="input-2"
          type="text"
          required
        ></b-form-textarea>
      </b-form-group>
    </b-container>
    <template #modal-footer="{ cancel }">
      <button-cancel @click="cancel()"></button-cancel>
      <button-save @click="saveChargeBack"></button-save>
    </template>
  </b-modal>
</template>

<script>
import buttonSave from "@/views/commons/utilities/ButtonSave.vue";
import buttonCancel from "@/views/commons/utilities/ButtonCancel.vue";
import ChargeBackService from "@/views/commons/components/charge-back/charge-back.service";
import { mapGetters } from "vuex";
// import CategoriesService from "@/views/management/views/categories/categories.service";

export default {
  components: {
    buttonSave,
    buttonCancel,
  },
  data() {
    return {
      selectedCard: "",
      onControl: false,
      description: "",
      isBusy: false,
      cardNumbers: "",
      totalRows: 0,
      items: [],
      sortBy: "created_at",
      sortDesc: true,
      fields: [
        {
          key: "radio",
          label: "",
        },
        {
          key: "card_number",
          label: "Card",
        },
        {
          key: "transaction_id",
          label: "ID Transaction",
        },
        {
          key: "amount",
          label: "Amount",
        },
        {
          key: "created_at",
          label: "Date",
          sortable: true,
        },
        {
          key: "client_name",
          label: "Client",
        },
        {
          key: "account",
          label: "Account",
        },
        {
          key: "advisor_name",
          label: "Advisor",
        },
      ],
    };
  },
  async created() {
    this.onControl = true;
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
  },
  methods: {
    close() {
      this.$emit("close");
    },
    setSelectedCard(card) {
      this.selectedCard = card;
    },
    async getCardsList() {
      try {
        const result = await this.$refs.form.validate();
        if (result) {
          const params = {
            card: this.cardNumbers,
            orderby: 9,
            order: this.sortDesc ? "asc" : "desc",
          };
          this.isBusy = true;
          const data = await ChargeBackService.getCardTransactions(params);
          this.totalRows = data.total;
          this.items = data.data;
          this.isBusy = false;
        }
      } catch (e) {
        this.showErrorSwal(e);
        return [];
      }
    },
    async saveChargeBack() {
      if (!this.selectedCard) {
        this.showWarningSwal("Please select a transaction", "");
        return;
      }
      try {
        const response = await this.showConfirmSwal();
        if (response.isConfirmed) {
          this.addPreloader();
          const data = await ChargeBackService.saveChargeBack({
            idtransaction: this.selectedCard,
            iduser: this.currentUser.user_id,
            description: this.description,
          });
          if (data.status === 200) {
            this.$emit("reload");
            this.showSuccessSwal("Charge Back has been added successfully");
            this.removePreloader();
            this.close();
          }
        }
      } catch (e) {
        this.showErrorSwal(e);
      }
    },
  },
};
</script>

<style scoped></style>
